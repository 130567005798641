import React from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './PageContent.module.css';

import Container from '../../../../blocks/Container';
import SectionTitle from './SectionTitle';
import LearnDetailsText from './LearnDetailsText';
import ImageWithCaption from './ImageWithCaption';
import BlockQuote from './BlockQuote';
import List from './List';
import Callout from './Callout';
import Subtitle from './Subtitle';
import NextSection from './NextSection';

const components = {
  sectionTitle: SectionTitle,
  learnDetailsText: LearnDetailsText,
  imageWithCaption: ImageWithCaption,
  blockQuote: BlockQuote,
  list: List,
  callout: Callout,
  subtitle: Subtitle
};

const PageContent = ({ page, nextPage }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/pages-content.md/" } }) {
        edges {
          node {
            frontmatter {
              pages {
                name
                components {
                  name
                  content
                  additional
                  items
                  ordered
                  href
                  size
                }
              }
            }
          }
        }
      }
    }
  `);

  const { pages } = data.allMarkdownRemark.edges[0].node.frontmatter;
  const currentPage = pages.find(iteratingPage => iteratingPage.name === page.name);

  const needBlockOffset = (component, index) =>
    currentPage.components[index - 1] &&
    currentPage.components[index - 1].name !== 'sectionTitle' &&
    component.name !== 'sectionTitle';

  return (
    <div className={styles.learnDetailsContent}>
      <Container type="big">
        <Container type="mobile">
          <div className="row">
            {
              currentPage.components.map((component, index) => {
                const Component = components[component.name];
                return (
                  <div key={index} className={`
                    col-xl-${component.name === 'sectionTitle' ? '3' : '9'} 
                    col-lg-${component.name === 'sectionTitle' ? '3' : '9'}
                    col-12 ${component.name === 'sectionTitle' ? 'd-flex justify-content-xl-end justify-content-lg-end' : ''}
                    ${needBlockOffset(component, index) ? 'offset-xl-3 offset-lg-3' : '' }
                  `}>
                    <Component
                      items={component.items}
                      additional={component.additional}
                      ordered={component.ordered}
                      href={component.href}
                      size={component.size}
                    >
                      {component.content}
                    </Component>
                  </div>
                )
              })
            }
          </div>
          {
            nextPage &&
            <NextSection href={nextPage.url} title={nextPage.title} />
          }
        </Container>
      </Container>
    </div>
  )
};

PageContent.propTypes = {
  page: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    next: PropTypes.string.isRequired
  }).isRequired,
  nextPage: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    next: PropTypes.string.isRequired
  }).isRequired
};

export default PageContent;