import React from 'react';
import PropTypes from 'prop-types';

import styles from './SectionTitle.module.css';

const SectionTitle = ({ children }) => (
  <div className={styles.sectionTitle} dangerouslySetInnerHTML={{ __html: children }} />
);

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired
};

export default SectionTitle;