import React from 'react';

import Layout from '../../../blocks/Layout';
import PagesListContent from '../PagesList/PagesListContent';
import PageHeader from './PageHeader';
import PageContent from './PageContent';
import SEO from '../../../../components/blocks/SEO';


const Page = ({ pathContext }) => {
  const { page, section, nextPage } = pathContext;

  return (
    <Layout>
       <SEO
        title="Learn | Deadline Funnel"
        description="Learn how to use Deadline Funnel to level up your email marketing and grow your business."
      />
      <PageHeader page={page} section={section} />
      <PageContent page={page} nextPage={nextPage} />
      <PagesListContent />
    </Layout>
  )
};

export default Page;