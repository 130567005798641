import React from 'react';
import PropTypes from 'prop-types';

import styles from './BlockQuote.module.css';

const BlockQuote = ({ additional, children }) => (
  <div className={styles.blockQuote}>
    <div dangerouslySetInnerHTML={{ __html: children }} />
    <div className={styles.blockQuote__author}>{additional}</div>
  </div>
);

BlockQuote.propsTypes = {
  additional: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default BlockQuote;