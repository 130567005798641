import React from 'react';
import PropTypes from 'prop-types';

import styles from './ImageWithCaption.module.css';

import Image from '../../../../../elements/Image';

const ImageWithCaption = ({ additional, children, href, size }) => (
  <div data-size={size} className={styles.imageWithCaption}>
    <a href={href} target="_blank" rel="noopener noreferrer">
      <Image  name={additional} className={styles.imageWithCaption__image} />
    </a>
    {children}
  </div>
);

ImageWithCaption.defaultProps = {
  href: '',
  size: 'regular'
};

ImageWithCaption.propsTypes = {
  additional: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  link: PropTypes.string,
  size: PropTypes.string
};

export default ImageWithCaption;