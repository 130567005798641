import React from 'react';
import PropTypes from 'prop-types';

import styles from './List.module.css';

const List = ({ items, ordered }) => (
  <div className={`${styles.list} ${!ordered ? styles.listUnordered : ''}`}>
    {
      items.map((item, index) =>
        <div key={index} className={styles.listItem}>
          {
            ordered ?
            <div className={styles.listNumberContainer}>
              <div className={styles.listNumber}>
                <span className={styles.listNumberDash}>&mdash;</span>{index + 1}
              </div>
            </div> :
            <span className={styles.listDot} />
          }
          <div className={styles.listText} dangerouslySetInnerHTML={{ __html: item }} />
        </div>
      )
    }
  </div>
);

List.propsTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  ordered: PropTypes.bool.isRequired
};

export default List;