import React from 'react';
import PropTypes from 'prop-types';

import styles from './Subtitle.module.css';

const Subtitle = ({ additional, children }) => (
  <h1 className={styles.subtitle}>
    {children}
  </h1>
);

Subtitle.propsTypes = {
  children: PropTypes.node.isRequired
};

export default Subtitle;