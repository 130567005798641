import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './PagesListContent.module.css';

import Container from '../../../../blocks/Container';
import Image from '../../../../elements/Image';

const PagesListContent = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/pages-list.md/" } }) {
        edges {
          node {
            frontmatter {
              sections {
                title
                pages {
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const { sections } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.learnPagesContent}>
      <Container type="big">
        <div className="row">
          {
            sections.map((section, index) =>
              <div key={index} className={`${styles.learnPagesContent__sectionContainer} col-xl-4 col-lg-4 col-12 d-flex justify-content-xl-start justify-content-lg-start justify-content-center`}>
                <div className={styles.learnPagesContent__section}>
                  <div className={styles.learnPagesContent__sectionTitle}>{section.title}</div>
                  {
                    section.pages.map((page, index) =>
                      <a key={index} className={styles.learnPagesContent__sectionLink} href={`/learn/${page.url}`}>
                        {page.title}
                      </a>
                    )
                  }
                </div>
              </div>
            )
          }
        </div>
      </Container>
      <Image name="learn_plant" className={styles.learnPagesContent__image} />
    </div>
  )
};

export default PagesListContent;