import React from 'react';
import PropTypes from 'prop-types';

import styles from './PageHeader.module.css';

import Container from '../../../../blocks/Container';
import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';

const PageHeader = ({ section, page }) => (
  <div className={styles.learnPage}>
    <Container>
      <Container type="mobile">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center">
            <div className={styles.learnPage__categoryName}>{section}</div>
            <Title className={styles.learnPage__title}>{page.title}</Title>
            <Text className={styles.learnPage__description}>{page.description}</Text>
          </div>
        </div>
      </Container>
    </Container>
  </div>
);

PageHeader.propTypes = {
  section: PropTypes.string.isRequired,
  page: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired
};

export default PageHeader;