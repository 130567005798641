import React from 'react';

import styles from './NextSection.module.css';

const NextSection = ({ href, title }) => (
  <div className="row">
    <div className="col-12">
      <div className={styles.nextSection}>
        <a className={styles.nextSection__nextControl} href={`/learn/${href}`}>
          <span className={styles.nextSection__nextControlTitle}>{title}</span>
          <span className={styles.nextSection__nextControlNext}>Next</span>
        </a>
      </div>
    </div>
  </div>
)

export default NextSection;