import React from 'react';
import PropTypes from 'prop-types';

import styles from './Callout.module.css';

const Callout = ({ children }) => (
  <div className={styles.callout}>{children}</div>
);

Callout.propsTypes = {
  children: PropTypes.node.isRequired
};

export default Callout;