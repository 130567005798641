import React from 'react';
import PropTypes from 'prop-types';

import styles from './LearnDetailsText.module.css';

const LearnDetailsText = ({ children }) => (
  <div className={styles.learnDetailsText} dangerouslySetInnerHTML={{ __html: children }} />
);

LearnDetailsText.propTypes = {
  children: PropTypes.node.isRequired
};

export default LearnDetailsText;